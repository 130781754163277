import React, { useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import NavbarWrapper from "common/components/Navbar";
import Button from "common/components/Button";
import Logo from "common/components/UIElements/Logo";
import Box from "common/components/Box";
import Container from "common/components/UI/Container";
import { DrawerContext } from "common/contexts/DrawerContext";

import LogoImage from "common/assets/image/foodDelivery/logo.png";

const Navbar = ({ navbarStyle, button, row, menuWrapper }) => {
	const Data = useStaticQuery(graphql`
		query {
			foodDeliveryJson {
				MENU_ITEMS {
					label
					path
					offset
				}
			}
		}
	`);
	const { state, dispatch } = useContext(DrawerContext);

	// Toggle drawer
	const toggleHandler = () => {
		dispatch({
			type: "TOGGLE",
		});
	};

	return (
		<NavbarWrapper {...navbarStyle}>
			<Container>
				<Box {...row}>
					{/* <Logo
						href="/"
						style={{
							width: "50px",
						}}
						logoSrc={LogoImage}
						title="Agency"
						className="main-logo"
					/> */}
					<Box {...menuWrapper} className="mainMenuWrapper">
						<Link className="navbar_button" to="#">
							{/* <Button {...button} title="Login Now" /> */}
						</Link>
						<Link target="_blank" className="navbar_button_two" to="https://merchant.gobig.app/auth/jwt/register">
							<Button {...button} title="Join Free" />
						</Link>
					</Box>
				</Box>
			</Container>
		</NavbarWrapper>
	);
};

Navbar.propTypes = {
	navbarStyle: PropTypes.object,
	logoStyle: PropTypes.object,
	button: PropTypes.object,
	row: PropTypes.object,
	menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
	navbarStyle: {
		className: "food_delivery_navbar",
		minHeight: "70px",
		display: "block",
	},
	row: {
		flexBox: true,
		alignItems: "center",
		width: "100%",
	},
	logoStyle: {
		maxWidth: ["126px", "126px"],
	},
	button: {
		type: "button",
		fontSize: "13px",
		fontWeight: "600",
		color: "white",
		borderRadius: "4px",
		pl: "15px",
		pr: "15px",
		colors: "primaryWithBg",
		minHeight: "auto",
		height: `${1}`,
	},
	menuWrapper: {
		flexBox: true,
		alignItems: "center",
	},
};

export default Navbar;
